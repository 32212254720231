import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E09 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname); 
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>{`${this.props.title} | ${this.props.subTitle} | ReelCast by ReelMetrics`}</title>

                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s03e09-bluberi-with-mike-brennan-casey-whalen"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s03e09-bluberi-with-mike-brennan-casey-whalen"
                    />
                    <meta property="og:title" content="ReelCast - S03E09" />
                    <meta
                        property="og:description"
                        content={this.props.description}
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/uvl2RfnQQFOybc2bNujq"
                    />
                    <meta property="og:locale" content="en_US" />
                
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

    S03E09.defaultProps = {
        id: "15767087-s03e09-bluberi-with-mike-brennan-casey-whalen",
        link: "https://www.reelmetrics.com/s03e09-bluberi-with-mike-brennan-casey-whalen",
        title: "Bluberi with Mike Brennan & Casey Whalen",
        subTitle: "Season 3, Episode 8",
        description: "In this episode, Nick and Don catch-up with Bluberi's Chief Product Officer, Mike Brennan, and Chief Commercial Officer, Casey Whalen. Learn how an insurgent supplier with roots in Quebec is parlaying a string of surprise hits into some of the most exciting product offerings for the Class III slot space. Hear about their design philosophies, banking strategies, release plans, cultural imperatives, and (music to our ears) obsession with fair share performance. Also in this episode, G2E 2024.",
        topics: [
            "Introduction to Bluberi's executives and their backgrounds",
            "Bluberi's company history and expansion into the Class III slot market",
            "Bluberi's design philosophy and intentional approach to game development",
            "The success of Devil's Lock and its impact on the industry",
            "Bluberi's banking strategies and focus on fair share performance",
            "Development and features of the Beacon Elite cabinet",
            "Bluberi's data-driven decision-making and use of market analytics",
            "Release plans and strategy for entering new jurisdictions",
            "Approach to leasing vs. selling slot machines",
            "Preview of Bluberi's plans for G2E 2023 and upcoming products"
        ],        
        keywords: [
            "Bluberi",
            "Mike Brennan",
            "Casey Whalen",
            "G2E 2024",
            "Class III slot market",
            "North American market",
            "gaming innovations",
            "slot machines & casinos"
        ],    
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/15767087-s03e09-bluberi-with-mike-brennan-casey-whalen",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e09-bluberi-with-mike-brennan-casey-whalen",
    
    transcript: `Nick Hogan:
Morning, Don, how are things in the Midwest today?
Don Retzlaff:
Good morning, Nick. It's beautiful here. How's things in Netherlands?
Nick Hogan:
Well, fall is starting or the weather has gone to hell. So here we go. We're in for the gray dome for a while here. Let's see. So since last time, Don, we were on, my news items that caught my eye over the past month, some anti-smoking activity lawsuit made its way to the New Jersey Supreme Court. So lawsuit relates to New Jersey's 2006 Smoke-Free Air Act, which banned smoking in public buildings with exemptions applied to both cigar bars and casinos. And recently a coalition, casino workers filed a lawsuit against the state arguing that since the state constitution apparently guarantees safety to New Jersey residents, the legal provision that exempts casinos from the smoking ban is unconstitutional. So the group petitioned, the State Supreme Court went after an emergency injunction to temporarily nullify the exemption while they litigate it in state courts. But on August 30th, the Supreme Court weighed in, shot down the request ruling that the exemption is fine constitutionally in that the constitution guarantees residents the government's pursuit of safety but not safety itself.
So Don, I don't have a dog in this fight at all, but I can't help but find that logic a bit spurious. I guess on an abstract level I can understand it, but when we're dealing with known carcinogens that have been banned in every other establishment in the state, I think arguing the exemption as an example of the state pursuing public safety would be a bit of a stretch. But in any event, the plaintiffs agree and they've requested a speedy appeal. We'll see where that goes, but it just does look like public opinion really is coalescing against these remaining smoking holdouts. I recall back in April, a group there in Nevada ran something that showed something like 60% of Nevadans now favor statewide bans including casino. So Don, where are you standing on all this? Do you have strong feelings about this either way?
Don Retzlaff:
It was really nice when Illinois went non-smoking because my dry cleaning bill wearing suits every day into the casino really fell. It was nice, obviously for the lungs and everything else. It really is. There's a lot of states that carve out casinos in the St. Louis market. They have to have a certain percentage of the floor. So I think eventually we're going to see it in our generation that you'll see no smoking in basically all public buildings. But it does impact-
Nick Hogan:
Yeah, I think it's just a matter of time.
Don Retzlaff:
It was really wild what happened to the Illinois casinos, but we had competition on the Missouri side too. So it was like they flipped a switch on January 1st and 25% of our customers stopped coming.
Nick Hogan:
But you saw those numbers were back then after a period of time.
Don Retzlaff:
Yeah. We had to make some adjustments on areas that they could smoke and smoking lounges and things like that. But it definitely did hurt and that's what everyone's scared of. They're scared of revenues falling, taxes falling, unemployment going up, all those types of things. So it's definitely a trade-off.
Nick Hogan:
Okay. Yep. Gotcha. Okay, Don, so time for this month's listener question, but before I jump into it, let me say that we love to tackle any questions that anybody listening may have. If you have a question about what we're presenting or something you'd like us to present, please just drop us an email at reelcast@reelmetrics.com. That's R-E-E-L-C-A-S-T @reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly and don't worry about us revealing your identity. That is not something we do.
So Don, this month's listener question, "Hey guys, just curious what you'll be looking for at this year's G2E." Comes from a listener in the American Midwest. So thanks listener for that question. Don, this year for me, it's all about slots, risk retina and Light & Wonder have been on a release [inaudible 00:04:18] this year. Plenty of stuff I hadn't seen yet, so it'll be a nice to take a look at that. And also just running through our shows this year. We've done now Aruze, Gaming Arts, AGS, IT, Zitro, and we're doing one more today and each of these is unveiling new cabinets and game series. So provided that I can actually get to the floor this year, that's what I'm going to be running around doing. How about you, Don?
Don Retzlaff:
Same thing. I try to hit all the vendors, look at the new cabinets, the new themes both for sale and the lease games. Always enjoy G2E. Been going out there for, gosh, almost 30 years now. So yeah, hint number one, make appointments with the different vendors so you're not aced out so you've got time with them. And two wear comfortable shoes. It is a lot of walking, you're on your feet an awful lot, all three days.
Nick Hogan:
But it's going to be all slots for you this year too, Don?
Don Retzlaff:
Yeah, for me it's pretty much all slots. Sometimes you'll try to go over and look at electronic table games or some of the new table games out there, the new stuff. But basically it's all slots. Unless I was looking for some equipment for the cage or bill validator's and printers and stuff like that, 98% of my appointments are slot related.
Nick Hogan:
Okay, very good. Okay, so Don, new kind of episode for us today in that we have two 20 year industry veterans whom for whatever strange reason neither of us has met personally. Secondly, neither appears overly big on creating LinkedIn profiles. I had to grab facts where I could get it, but just generally these gents have a combined 40 years of gaming industry experience. All of it on the supply side includes leadership tenures with IGT, Bally Technologies, and Konami. Functionally they've held roles in sales, marketing, product management and development, all of it related to diversified product portfolios. And these days they serve in the C-suite of a company that's been turning a lot of heads over the past couple of years. And that is Bluberi. I'm referring of course to Bluberi's chief commercial Officer, Casey Whalen, and it's chief product officer, Mike Brennan. Good morning, gents. How are things in the American West today?
Casey Whalen:
Doing well. Thanks for having us.
Nick Hogan:
Alrighty. Okay, gents. So as I stated in the intro, we haven't met and we don't know that much about one another, so this is really going to make its way in the question. So what I wanted to do here is go one at a time and before you tell me how you broke into the industry and what led you to your current roles at Bluberi, I'd like a bit of explanation of the cartoon caricatures that you guys have up on your website featuring each of you. So Casey, in your case, we have a guy in Vans throwing a shaka and holding up what looks like a 7'0 fishtail board. So I take it you're the guy who makes friends in hostile lineups. Is that the deal?
Casey Whalen:
Wow, look at the vernacular there, 7'0 fishtail. There's a guy that's been on a surfboard before. But yeah, I would give Mike and the product management team, they're a little more creative than myself, so those are kind of their ideas there. But yeah, I love to surf. We tried to find a little bit about each of our personalities that sort of stands out and I kind of have some longer hair and a little bit more of a laid-back vibe. And I am super chill. I have a lot of ohana in the lineups, so I'm happy to share waves. I'm not one of those angry people defending my own local break.
Nick Hogan:
What is your local break, just out of curiosity?
Casey Whalen:
It's a lot of San Onofre and Doheny if I'm on a longboard. And if it's shortboards, it's Huntington Beach or El Porto in the South Bay.
Nick Hogan:
Oh man, Huntington Beach. That's that wedge wave there, right?
Casey Whalen:
Yeah, you can't really surf the wedge. That's a body-surfing wave. But yeah, I'll surf Huntington Beach, the pier on the north side, or you can do the cliffs or Bolsa Chica. If anybody surfs Southern California, they know just about every place I just touched on.
Nick Hogan:
Okay, very good. And let's see, so I have it right, you started off with Bally, was that right?
Casey Whalen:
Yeah, actually coming up on 20 years, I started out selling in Southern Nevada. My first territory was Laughlin, Mesquite, Pahrump. And so I said everybody had to have their own strip and my strip was the Laughlin strip. So yeah, I started there right actually when Bally bought out the Alpha platform from Bob Luciano. So there might be a callback on Bob Luciano's impact on my career later. But that platform really stabilized Bally and allowed us to start to compete again with steppers on the backside of the S6000.
Nick Hogan:
Absolutely.
Casey Whalen:
Went into management for a little bit there in the west and got a bigger opportunity with Konami to manage about half the country. Ended up running the whole country as vice president of sales for Konami and then married up. I knew Andrew personally and we got along well. And then in the fall of 2019, we started talking about an opportunity with a smaller company and maybe some of the things I learned at the bigger companies I worked at, I could come and we could kind of turn this little company around. So yeah, I've been here four full years. I'm in my fifth year now and it's been super exciting. I can't believe everything we've been through, but that's a little bit of my elevator pitch.
Nick Hogan:
Okay, cool. And let's see, Mike, your caricature is in a pair of Nikes with big grin and his arms folded across his chest. And I looked at this, it didn't give me much, but then I saw a few of the alliterations that are attributed to you, without data there's doubt. Now Don and I love that one, that's for sure. Quantify the qualitative. We're with you on that one as well and save it for the sequel, which I have I a few versions of that that I use pretty frequently. So this caricature tells me you're the guy who says, "Prove it." Anywhere [inaudible 00:10:35].
Mike Brennan:
Yeah, for sure. Without the comparison, it's hard to tell. They made me tall, so that's my defining... Unlike Casey's great story in there, it's just like I happen to be born tall, so that's what I'm bringing to the table. I can sure paint a ceiling if you need help, outside of that, then it's kind of some of the axioms we talk about.
Nick Hogan:
All right, great. And I see you started at IGT then, is that right?
Mike Brennan:
Yeah, so this cool story, I started actually with Atronic, if you remember. So there's a connection to Western Europe there where you're at.
Nick Hogan:
Absolutely. The Gauselmann group. Michael Gauselmann [inaudible 00:11:11].
Mike Brennan:
For sure. And then the protracted acquisition from [inaudible 00:11:18]. Then we changed our name to Spilo, International Spilo, then G-Tech, and then the acquisition of IGT. So it was four companies, but really one group for almost 17 years.
Nick Hogan:
Okay, great. And then when did you make move then into Bluberi?
Mike Brennan:
Yeah, about actually three years ago almost on the dot, just things are going well, but there was this opportunity as Andrew Burke was going Blues Brother style, putting a band together and we just low expectations in our first lunch meeting and we just really clicked and seemed like kind of a theme here. It's like a rare opportunity, a company with bones that was able to grow. We were only in maybe 15% of all North American jurisdictions when Casey and I joined. And so it was just like if we could get this thing right, there was nothing but growth opportunity. And for sure it was challenging, still is challenging, but best decision I ever made and we try to do things a little differently here and we're just having a lot of fun. It's not easy, it's been a challenging road, but it's extremely rewarding and it's been definitely best experience I think of almost all our careers. So it's been extremely fun. And we're still rolling. We're just getting started.
Nick Hogan:
All right, cool. Well, yeah. Going back at a bit of history. So I'm sure plenty of people listening, they're not all really familiar with Bluberi, so I know there are some Canadian roots and whatnot. So which one of you wants to take the quick company history summary?
Casey Whalen:
Mike's pretty good at that stuff. He deals with a lot of our R&D folks in this epicenter of the company there from Canada. So Mike's the one to handle that.
Mike Brennan:
Yeah, I think a lot of us we're kind of students of history. We like to know where things came from and Bluberi has a long history. The story we tell that kind of encapsulates it is we just had an employee who retired, Luke, after 31 and a half years.
Nick Hogan:
Oh wow.
Mike Brennan:
So Bluberi in some sort of way, shape or form has been around for over three decades. Its name might have changed a little bit, but definitely for 25 years was an established technology and software producer mostly in Class II systems and Class II games. You guys have been around North America, some of the regional hits like Royal Reels in the Southeast in Florida to Cool Cats and some other games sprinkled across other Class II jurisdictions were all Bluberi games before Bluberi decided to make hardware. And it is true and true [inaudible 00:13:49] company based in a city called Drummondville, that's right between Montreal and Quebec City. And it's just a extremely proud team over there that has been around, some of our founding folks have been there over 20 years. So they were doing their thing, got into land-based gaming almost a decade ago. And then just through a couple changes of leadership, Andrew Burke came in five years ago and since then we've kind of diversified the geography of the company.
So just kind moving some things that made sense to move into the US, like manufacturing, ops, shipping, some other things. And now we have an amazing team still in Quebec. They're our largest center of excellence. They do hardware, R&D, engineering, IT, program management, and we have two amazing studios there. And then as we'll talk about, we expanded from there to bring some functions to Vegas. And then we spun off three additional studios over the last two plus years. One in Reno, one in Moncton, New Brunswick, which is an amazing place that has some good history there. And then earlier this year, we opened one in Austin, Texas, the old Jackalopes. So yeah, big Quebec routes that we're still contributing a lot of our success, but just a bit more sprinkling in some other folks south of the border.
Nick Hogan:
Now did you bring in, when you set up the headquarters there in Las Vegas, that was this year, was it not that you opened the headquarters?
Casey Whalen:
Well, we actually expanded our offices. We've had a great four-year run here, I guess you would say turning things around. But we had a smaller facility and we sort of outgrew it. I'd say 18 months ago we had 100 employees. We're up to 235 employees now. So we actually just went from about a 22,000 square foot facility in Las Vegas and moved a couple miles away to 88,000 square feet. We're still only really licensed to about half the jurisdictions in the country, so we're not even delivering games or in the markets of Nevada, New Jersey, Pennsylvania, just to name a few. So we knew with the growth ahead of us, we need a bigger facility. So we've been in Vegas for a while, we still have tech support and a bunch of, like Mike alluded to, some of our R&D folks in Drummondville and outside of Quebec City, halfway between Quebec City and Montreal. But yeah, the headquarters in Las Vegas has kind of been relocated here with accounting and finance and other things for the last three and a half years.
Nick Hogan:
Okay, gotcha. Did you bring many Canadians down into Nevada or is that all local recruiting that you did there?
Mike Brennan:
Yeah, we haven't done a lot of relocating. For sure it's pretty much folks, this is neat, folks from across the industry. So we pretty much touch experience for almost every vendor. We have people coming from outside the industry and then within the industry, just given an option to try something new. So yeah, this is all homegrown growth. We've more than doubled our employee count. So we're up around 230. And when Casey and I got on board, it was around the century marks, about 100 folks. So yeah, we've really been growing organically really through starting up these regions and just recruiting from local talent.
Nick Hogan:
Okay, cool. So guys, you know that obviously breaking into the American Class III slot market is no easy feat, it's complex, it's costly, and oftentimes we see that the success rates aren't so hot. So where do you really see Bluberi's niche here getting into this very crowded and competitive industry here in the North American market?
Mike Brennan:
That's a great question. I think we did it from being a little different. I think timing is important too. If you know anything about Montreal, there's a lot of video game companies there and art and presentation, like motion graphics, animation and user interface, user experience, UX UI is really big up there and I think the team that's always been here for 20 plus years is really strong in that domain. And then we mashed this thing up with some kind of American experience. I think we took on the North American Class III market with games that just looked and felt a little different. Production values high, this is when cartoon style games were accepted by players. There was a while there where you could never put a giant cartoony devil probably that takes up 30% of the screen on your portrait cabinet. It had to be gambler gold and deep red Asian themes.
So yeah, I think we went in, timing was pretty good and we're doing things I think a little differently because we have to maximize our bets is the term we used a lot. We have a much smaller but growing R&D budget, and so we just had to make sure that every game we make had a shot to be something on trend that players are looking for with a bit of a twist. We can talk about that too, how we sprinkle in really popular mechanics with kind of older, maybe not as utilized mechanics. We think that's a powerful recipe for creating something that's familiar but a little different. And then combine that with that presentation. I talked about this real big strength for the team and I think we hit the market at the right time with the right talent and really focused on making sure we go in, understanding how challenging it is and being very thoughtful and intentional on every product release.
Nick Hogan:
Yeah, okay, cool. And it's interesting you mentioned that was about the character with the Devil's Lock, because that is indeed such a standout element of that game that this little character that has really a kind of nasty little personality too, is a cool setup there.
Mike Brennan:
Yeah, [inaudible 00:19:51].
Nick Hogan:
And with that product, I have to say that, and this is more of a commercial strategy point, but when it became clear that that title really had legs and was really starting to move, I remember I had a conversation with some guys at Bluberi at that time, but what we really liked was your banking strategy on it. So what we saw was you guys putting that stuff out there and really featuring that title that you knew was that it was solid, had legs and you weren't jamming the banks full of all kinds of other stuff that you guys were trying to push out. And we really liked that focus because it's something that Don and I, the data totally support doing that, just keeping the game count low on those banks. Why don't you talk a little bit about your banking strategy, how you rolled that game out, because I thought that was something very notable?
Casey Whalen:
Yeah, I would say we've worked some other places where maybe you get this piece of hardware out there and the plan for what the software looks like behind it as people depreciate over 60 months, you can get a little bit underwater if you don't have the requisite number of quantity or quality of those sort of things or overselling to the footprint that you're capable of supporting. And I think one of the things when we all came here from somewhere else is like, hey, are there some things we learned about where we were before that we want to bring forward? Of course. And then there are some other things we can leave behind. And I think we talk a lot in the building about fair share, meaning if you're a certain percentage of the floor, just make sure that revenue is greater than that percentage of the floor that you own.
And so by making sure that we were super specific and strategic about where the games went, how many we gave to the floor there, what's the customer like, where are we in the region, all those sorts of things are conversations that not only do we have then, we're having them today. There's some other things coming down the pike for us that are going to happen at G2E that we're being super thoughtful about. What is this forward-looking piece of hardware and software? What's our plan look like? We just entered the historical horse racing market in the last year and how do you walk into that vertical and be super thoughtful about how much of the floor you're willing to own that you're comfortable to own?
So those are conversations we had and thought about prior to Bluberi since we've been here, and we'll have those going forward as we enter new markets or look at our saturation in other markets. So we're really thoughtful and measured. I think we'd say we'd rather take a few less games than a few too many games. If somebody comes back in a quarter or two and says, "Hey, I'm ready for my next swipe of more Bluberi games," and that's the sort of position we want to be in.
Nick Hogan:
Okay, so Don, how's that for a healthy perspective?
Don Retzlaff:
That's perfect. [inaudible 00:22:38].
Casey Whalen:
Mike, do you have something to add there? Yeah.
Mike Brennan:
Yeah, just to echo, I think the reason maybe Devil's Lock wasn't salt and pepper with other games and we really embraced it is nothing attracts a crowd like a crowd if you have a good game. But I think it's also reflective maybe of looking at Bluberi's structure is we're small and agile and we pride ourselves on a strong customer and sales to product and R&D relationship. I think we bridge a lot of those gaps and so we really try not to point fingers at each other, which is inherently those groups are set up to have built in scapegoats for each other.
And so when we're rolling out products, we really try to align and make sure that hey, we're being strategic, let's believe in each other. And so with Devil's Lock, it was just kind of a no-brainer. It can carry itself. And then if you can get that critical mass of games next to each other, players can see maybe some depth of the game that they weren't expecting. We call that billboarding. So we're trying to do that when we can. You have a really strong game and then you can see the depth, you see the winning. We're going to talk about more of the Devil's Lock, but there's just a lot of winning events and iconic audio stingers when the devil laughs. And if you just have more of them next to each other and you have a good game, that's the first step, which is the hardest step you can absolutely leverage and create that little frenzy of player affinity.
Nick Hogan:
Excellent. Okay, Don, I know you're a champion of the bid here with all kinds of product questions, so I'll shut my mouth and let you go.
Don Retzlaff:
Thanks. You mentioned some of the jurisdictions that you are not in yet. I know it's very costly and time-consuming to enter these jurisdictions. Is there a plan in place to access these areas and how quickly do you think that'll happen?
Casey Whalen:
Yeah, Don, that's a great question. Another one we talk about all the time is what order do we enter, what jurisdiction? I think one thing we wanted to do was be super thoughtful about making sure we were kind of almost a guaranteed winner or we had something that was compelling if we did enter these larger markets that are costly and time-consuming. We are owned by a parent company out of Toronto called Catalyst Capital, so we have a private equity factor we have to look at as well. But I think one thing we wanted to do is we just take Nevada for instance, is we wanted to make sure so much of the big three, they capture so much of the budgets of the larger corporates there that we wanted to make sure that if we did come to town we were familiar and we felt good about the product and the things we were bringing to market.
So a lot of those Nevada corporates, we've worked in regional properties, so we work with Caesar's in Florida or MGM in Maryland, et cetera. And so for us, we wanted to be thoughtful about when we came to the markets and make sure we were prepared to enter with something that was compelling and that we were kind of this known entity. So we have roadmapped out when is it appropriate to go to New Jersey. Some of these things take a little bit longer. Pennsylvania takes a while, so does Nevada, but we work with our parent company and worked locally here on when does it make sense to come to the markets.
Our plan is to come to all of the North American markets. We've just recently gone into Alberta and Ontario. We're looking to hopefully enter Quebec before the end of the year. So all of North America for us is super interesting and compelling, and then we just got to make sure we deliver it in a thoughtful manner, make sure that the hardware's ready to go approved in certain jurisdictions, because they ask for different things along the way as well. So our plan is to enter all the markets eventually, and some of them will happen immediately and the rest will happen over the next couple years.
Don Retzlaff:
Excellent. We see that with a lot of other, the smaller manufacturers too. Heck, I've been doing this since 1991 and there's still people I still can't buy for that have been around for 20 plus years, is just the way it works. The one thing that's fascinating to me about Bluberi was you don't release families of titles, it's all individuals. And you don't see that from other manufacturers. The other manufacturers are releasing two and three similar themes at the same time. Is that an intentional strategy or is that something just the way the games have been developed?
Mike Brennan:
Yeah, great question, Don. Absolutely intentional. Intentional, kind of based on circumstance a little bit and strategy a little bit. As I mentioned, we've really almost doubled R&D, but we still are playing a bit catch up to our competitors on how many games we can release. And so you're going to spend the effort and time on, let's just say a game idea, game seed, a game concept. Do you really want to double down and create two versions of... Sure, it's more efficient to do an n+1 or a variant or a family, but it still has to go through math testing, QA, VMM. So it doesn't mean we're not going to change the strategy, but right now we realize put it all into one. This is kind of the save it for a sequel too. There's a lot of variants that are clones that just have a change in color or from a panda to eagle.
We actually did an analysis 3G two weeks ago about how many pairs there were and how consistent the naming was, and it was just something that was... Our customers can get that from anybody. So we wanted to go in with, again, part of our philosophy is like you need to have an art style that is not offensive to really anybody. So it should be broad enough to appeal to almost anybody to try it. But within that kind of genre or style, it should be unique enough to make it stand out. And so yeah, we go all in on our themes. In fact, when I started, we had a great healthy internal debate about look, why not do a safer Asian theme and a more radical theme? And actually the team did that with Frogged up an Emperor Mystery. These are some great games from Bluberi's past that we ported to Novus B49 cabinet, but they're clones.
One has a cigar-smoking, drinking frog in sunglasses, and one has a serious emperor kind of Asian warrior. And so we kind of talked about, well, one did well in one region, one did well the other, and it was a good kind of almost backup and safety plan where we said, "Nope, let's go all in. Why take the safer route? Let's just do the smoking frog with sunglasses. And it's just different enough that'll appeal to folks." And that's kind of the philosophy we've had of save it for the sequel, you're going to go in, do it with one. Don't mitigate your risk by then, 'Oh, I like two bonus features. Let's make two games and tweak one in there." Pick the right one, pick the best one and limit yourself and go all into that.
So that's been our philosophy. Doesn't mean it won't change as we grow. We need to support larger banks, but for sure it's like we're going to throw it all... We say we make core plus games too. Not to be cheeky, but it's like we really have more effort and production values and iteration in every game to hope that it can hang with bigger banks like you guys mentioned. And just that visual consistency, that ability to learn from each other can really work if you really narrow in on the right one game and stay focused on it.
Don Retzlaff:
Excellent. You mentioned clones and a few years back I dubbed one of the G2Es as a Attack of the Clones as every manufacturer was developing their own Hold and Spin games. After the success of Devil's Lock, I expected to see and have seen some variants of that from other manufacturers. Does that flatter you or aggravate you?
Mike Brennan:
I'll start because it doesn't really aggravate us at all. I think when you're doing that, everybody does this in the industry, you're already behind. So what we try to say is, "Hey, we can tweak and improve our own concept probably before someone else could do the same." So I think it's when some ideas get diluted or maybe rushed. There's been a lot of center or this term from a competitor like, "Oh, center magic game." Like, oh, center magic, okay, that center character.
But when we released Devil's Lock, which was a lot of time in the making, there were only a couple independent reels games being released in the market. So everybody went away from the 15 independent reels in the base game. So now it's coming back and we're moving on maybe to something else and trying to evolve that whole Lock family to something else. So I don't think we were really aggravated at all. It was surprising at maybe how quickly it went. I went down to AGE in Sydney this year, and there were actually two Australian only games that had a Devil's Lock inspired center character. That's where it was pretty cool to be like, wow, this thing went to a market that usually doesn't consider North America a place to react to. They like to think it's the other way around, [inaudible 00:31:31] some shots down there. But yeah, for sure. I don't think we were aggrieved. But Casey, your perspective.
Casey Whalen:
I was actually flattered at G2E last year. There were a bunch of Devil's clone inspired games, I guess if you'd say it that way. I had more customers come in and say, "Hey, have you seen that over there?" And it was almost like they were taken up for the little guy, for us, that they were in our corner and appreciative of what we'd sort of done. And I think we've kind of already moved on to the next cool things we'll be doing and hopefully that other people get inspiration from it as well. So nothing other than we're just thankful for where we are today and super humble. And I think Mike said at the beginning, our best days are in front of us.
Don Retzlaff:
That's awesome. And the neat thing too is no one's really had great success. There's been some marginal successful themes from that, but it was kind of like when the Hold and Spin first came out, everyone tried it and most of them failed. It took them a while to get those play mechanics and that math right. So it's just a testament to how well Bluberi has done with that. I did notice earlier this year that you released the Beacon Elite cabinet. How long did that take to develop and what are the enhancements over the Novus series?
Casey Whalen:
I'll let Mike start there and I'll get to talk about the cool sexy stuff after it, but Mike put a lot of time into it with the team.
Mike Brennan:
Yeah, just to hark you back to the imitations or inspirations, because we all do it, but it's like I think it's really hard to understand the choreography and the care and the love that goes into a game. And if it was easy, then a clone would be a guaranteed hit. The fact that it's difficult to emulate a game that does well is because this industry, what makes it so fun, it's like without data, there's doubt, but there's the messy side of it. There's the creative, artistic, passionate side that's hard to quantify. And I think just how difficult it is to take a concept and make a spin of it and find success just shows that there's a lot of in between the lines, there's a lot of success that happens that's really hard to put your finger on. Oh, sorry, go ahead.
Nick Hogan:
Oh no, I was just going to say we spend a lot of time, it's something that we call, we have a metric for it, it's all about emotional resonance, right? So where is that emotional resonance? And yeah, you are not kidding when you're saying that that is elusive to really drill down on which attributes are really creating that resonance. I mean, my God, if you really talk to game designers and you really have with all the doors closed and nobody listening and they say, "Yeah, to a certain extent it's luck." How do we combine all of these attributes to really get that? But it is so elusive and so complicated.
Mike Brennan:
We try. We do some player intercepts afterwards, smaller sample size, and we got some good data I think on the Lock family, and we did it with Rocket Rumble our game, and we were a little surprised to hear, oh, the characters which we thought might not fit with the math profile we're embraced by players. So I think if you can just of smudge out some of that unknown and get a little bit of information around it, kind of help you put it together and realize that it's process and creativity, it's science and art and try to let teams have their creative passion come through in the finished product.
And sorry, not dodging the question, Don, but for sure our cabinet is another part of it. So another part of Bluberi's history, we have an awesome hardware and engineering team up in Quebec. They made boards and they're just so fast at creating things, and this is a totally internally designed cabinet. And actually at the show we're going to show where it came from. So some people do foam core prototypes on hardware. The team up there did a full wooden gorgeous prototype back in the day back in 2020.
Nick Hogan:
Wow.
Mike Brennan:
And so that's kind of when we started this Beacon cabinet. We're going to bring it back at the show and it's going to be part of a cool activation we have. But that's how it started. And then we kind of paused on it because the Novus B49 was still new in a lot of markets. When you're rolling out new markets, it's hard because it could be three years old cabinet A in this jurisdiction and three days old in jurisdiction B. But we've embraced it, we launched it in April. Beacon Elite is based on the Beacon cabinet, and actually right now we're just launching Beacon+, which is the for sale version of that cabinet. And Casey the team are out there riffing on the selling propositions, which I'll hand over to Casey right now to rattle through those.
Casey Whalen:
Yeah. The nice thing about this Beacon Elite, and I think we have this very talented team that Mike's talked about a couple of times, but we had vendors and techs and everybody come in and kick the tires on the cabinet and they picked up some 89 points of feedback on the cabinet. We actually incorporated it all into the game, and so when people see it's user-friendly underneath there, it's very intentional. We talk about intentionality is one of our core things, our hallmarks at Bluberi that help us stand out, but you'll see it at the show, the way the bezel and the lighting, it's top of the line.
We're super happy about how it looks. Again, we have 400 of them out today in this Beacon Elite packaging. We'll have 600 by the end of the year, but we will start selling it again at the show with a line dedicated to supporting that cabinet. So we're really excited. We have had great feedback so far in our pre G2E meetings, customers coming in from across the country looking at our road map, but also the hardware. Getting to look under the hood if they hadn't looked at it before. So the excitement is palpable. We're really looking forward to the show in a couple weeks.
Mike Brennan:
And I think it's maybe a microcosm too of a couple things that we're not afraid to put in the hard work. I think everybody works, but with this cabinet, we put it in front of our technicians manufacturing and then we actually were like, we're going to make that change. We're going to try to ensure that the innards of the cabinet have enough room for [inaudible 00:37:31] ticket paper, things that make it more serviceable. I think we're trying to make sure we're not skipping steps. That we're avoiding any potential issues. We're really trying to try to nail everything and also listen and be willing to almost delay things to get right, which is probably the hardest thing. We've done that with games and we did it a bit with this cabinet. We wanted to get it right, or at least in our mind, I feel like we got something that is the big picture with serviceability, aesthetics, ergonomics, technology, and we did take our time and that's why we're excited to launch the Elite, the premium version early in the year and then go with the for sale iteration right now.
Don Retzlaff:
Don't discount how important that is to a casino operator. Obviously game performance and win per unit's super important, but there is a tiebreaker, because there's a lot of games that perform in a similar range. I remember back in the early days of Konami, Casey, I know you'll appreciate this. I was talking to a good friend of mine, Alan Cripe, who you worked with, and the original Konami boxes came out and they were Cadillacs. I mean you put them on the floor, you never had to open them again. And there was just that comfort feeling to say, "Okay, they're an upcoming vendor, but they know how to make a game and I'll trust it because I don't have to spend any repairs and maintenance costs on these games." They're built well, and that's really, really important. It gives you just that extra confidence. I guess the big question for me is how many themes are you going to be rolling out in 2025 to support both the new Beacon cabinets and the Novus cabinets?
Mike Brennan:
Great question is number of themes is something that we're pushing against as like a KPI in the industry as where we grow.
Nick Hogan:
That's okay. I think it's actually healthy, honestly. I'm not kidding.
Mike Brennan:
No, but we're going to have over a dozen, and that might not sound like a lot, but I mentioned that we are focused on this kind of core plus idea. And we also do have a test bank program that we don't talk about a lot because we've had a lot of demand on our product that we've been slow playing that. Good example, Shark's Lock, we test and released it, so we put it on test bank and we released it and Casey and the team sold like 1600. Again, only in half the jurisdictions we're in within five to six months. So it's like there's definitely demand out there for product. But yeah, we'll have over a dozen games. As I mentioned, we'll have contributions from five studios and one exclusive third party. So Casey mentioned Bob Luciano, his son has a third party, and their exclusive land-based for us.
We've worked together and they're doing some really cool work for hire with some good creative kind of shared responsibility and they're killing it. Their first game is coming out soon. We've got our Reno studio has their second game getting released. So we're starting to get this kind of franchising, maybe the Bluberi studio approach. Everything we've done up to this point has been our amazing two studios and team up in Quebec. Our first game from Reno is out through Frenzy and doing really well just halfway through test bank.
And so yeah, the metric we're looking for is hit rate and rate of change is something you guys might get a kick out of that we track. Surprised we haven't talked about it yet, but we have a really great data team here. We say we kind of over invested two years ago in certain avenues, and when we mean that for our size, we might have a bigger team than people would ever think. So data is one led by Andy over here, and we track things lifecycle from month zero to month one and rate of change is the big one. What is this thing? We actually have the series, Double Flamin' Link, where we did a variant. It was a Hold and Spin mashed up with split symbols. So a great mechanic from the past, a great mechanic that's relevant, and it actually got a little better over time. Those things are unicorns. Didn't start super high, but it just kind of locked in and went up and so [inaudible 00:41:45]-
Nick Hogan:
So Mike, what exactly do you mean by rate of change? What are you tracking there?
Mike Brennan:
Yeah, so from that calendar month, from lifecycle, from let's say day one to day 30, day 30 to day 60, how is the index at that spot performing, right? Does it go from 1.2 to 1.15 and is it a 5% tail off, 8% month over month or does it fall off higher or more than that? Does it fall off lower? Part of it is how high you start, because it's obviously if you start higher, you're going to have probably a higher rate of change. But yeah, that's where we're looking. Devil's Lock actually started really high and has a really low rate of change. It's just to understand, do you have a 60-day wonder or do you have something that is going to be sticky for a while?
Nick Hogan:
Okay, gotcha.
Casey Whalen:
It allows the sales team to walk in and you're talking specifically by serial number at the property level. Somebody isn't getting dissuaded or thrown off by third-party information. We're talking by serial number. How is this doing on your floor? So this allows us to be a step ahead of most of our competitors, is how we feel about that. And if I could say one thing on the number of themes, Mike and the team have, we talk about intentionality all the time, but we only develop to portrait style games. We don't have dual screen. So when people get caught up in the numbers of how many titles you're turning out, we really design to one format and people understand the roadmapping and what it looks like with our footprints and the commensurate number of pieces of hardware we put out there. So it's a pretty thoughtful and intentional plan, and a lot of people come away feeling pretty comfortable with how we build games, not doing the clones like we talked about, et cetera. So it's a pretty good story. That's a lot of titles.
Nick Hogan:
Yeah. I can feel from you guys, you feel that that number game is a bit of a BS metric. I certainly look at it that way. It's way more to us about the percentage of those that actually hit, and we look for very specific metrics than what they're doing. But I think it's obviously a Wall Street obsession it seems more than anything, how many titles that people are putting out every year.
Don Retzlaff:
The big thing for us is we know that most studios only have 25 to 30% success rates on themes that they release. So obviously if you're releasing 12 themes, you've got to have a higher than a 25% success rate, or you're only releasing three titles, four titles a year that are successful. So that's the big thing for that. And plus you don't have a whole lot of cabinets of support, which is always good. If you're not doing conversions on them, you end up missing really good themes, which is great. And I forgot that Andy was with you guys. I knew Andy back in the days when he was with Konami and obviously an excellent analyst and so [inaudible 00:44:31].
Mike Brennan:
Yeah, we've got a full team. We've stocked them up. And Casey mentioned that we actually have this program called Bluberi Pop where we're able to have a Tableau vision of each of our customers that give us data or not, and trending and theme saturation. And one more note too is I want to make... We've got our first game coming from our New Brunswick studio, Pixel Jam, and I think what I've been saying a lot in this media season of G2E is that we're not going to turn any of these into a factory. By opening these different studios they can, Don, do what you said is try to have a higher hit rate, because they're not expected to do more than four to five games a year. So they're not like, "Oh, I've got to do this metric." I do believe that almost every game that started in the industry started here, and there's just a series of time and maybe the tough decisions over its development process that brings it down here.
It's like if we can give people the right inputs and enough time and enough ability to feel passionate about the product they're working on, then we can create these kind of studios that throw everything into these core plus games and don't have to churn out... And surely there's pressure. I know they're feeling it as I say this, but we're not turning anything into a factory. We're trying to create these independent Bluberi franchises where they can... What we've been saying in the season two is we're all playing from the same songbook. We have the same kind of notes, but everybody's playing their own instruments and maybe they're playing some notes longer than others. They're really interpreting the songbook a little differently. But that's what we're trying to do and we'll see the next year how it goes, but we're off to a good start and we have the right people and talent to hopefully get us there. I'm bullish as Casey is on our kind of aggressive, ambitious approach for the next couple of years.
Casey Whalen:
[inaudible 00:46:22] to Mike's credit at the end of '22, he said, "Man, we need more studios in order to keep our artisanal approach." And today we're talking about what's going to happen in 2027 that we wish you would've done today. And so we're always kind of trying to see a little farther down the field to make sure we're delivering and doing the things that keep us unique.
Nick Hogan:
Yep. And piggybacking on Don's question about the releases, what is your split in terms of lease and for sale? What's your strategy there going to market?
Casey Whalen:
Go ahead, Mike.
Mike Brennan:
Yeah. We have a pretty simple is we got into the lease business, which is it's just jumping into the deep end without a floatie or multiple. I mean, it's so competitive, there's so much choice. And so we're just looking for one bank per property that has four units. That's our Beacon Elite set up as a diamond, that's the one configuration, one bank. A couple of people have two banks and appreciate that. And our goal is to have enough releases to support that, whether it's four months later or six months later. We've got a roadmap where we have four families that are in development, so we will have enough conversions if needed because we know the metrics higher. We know how brutal it is out there. And so our goal is to get recurring revenue, which is more than just a premium Class III, II. Class II is a huge past and future of ours. And so that's big for us core leases, but it really helps us smooth out everything. So if you can get recurring revenue, it gives kind of this predictable kind of cash flow so we can keep growing, right?
We say we're not giving dividends to shareholders. Everything we take in, we're putting back into the business. We'll be recruiting a sixth studio at G2E, we don't know where it is. So whoever is interested in starting a studio, we're going to go find him or her and do it again. And so we're trying to get the balance there. I'm not sure what the number is exactly. We'd like for it to be well-balanced and consistent, but it's important that we get the recurring revenue because sales... We have this phrase, internal sales is lumpy. And if you can make up the lumpiness with the consistent incoming recur, it sure helps.
Nick Hogan:
Yeah, and at ReelMetrics we are very pro lease. Where we advocate it, good strong lease portfolio pretty much everywhere. I think it's getting a little bit easier to sell these days. I think people are starting to, we did a ton of work on the whole cannibalization argument and largely just proved that it's a lot of BS, not much more to it. It seems a bit easier. But yeah, we're always supporting premium portfolios. Glad to hear that you're going out [inaudible 00:49:10]. Sorry, Don, didn't mean to interrupt you.
Mike Brennan:
Sorry, one quick one you got us passionate on a Monday morning is like, it's also hard because we also want our core, our performer premium, we don't want to put everything, our best products in the premium portfolio. It's like that's the hardest thing is like, well, how do you ensure that your best stuff is going premium? We don't always want it that way. We want our customers to be able to buy the home runs too. And so we're trying to do little things to merchandise, to focus on the higher denoms and making sure we have the right cost to cover for a $1, $2.10. We actually got rid of quarter, so I'd love to talk about that to you guys.
We saw that as maybe a denom that wasn't helping us, and it's kind of a strange one to do the math. So we skip, we go 1, 2, 5, 10, $1, $2. So that's a little aside there. But for sure we want to make sure that we're trying to give our premium products the best chance to succeed, but we're not just moving everything awesome into that part of the portfolio.
Nick Hogan:
Yeah, yeah, yeah, yeah. And are you doing any WAP at all? I would assume you're probably skipping that.
Mike Brennan:
No.
Nick Hogan:
Yeah, good idea.
Casey Whalen:
Yeah. One other thing as a salesperson, what's so nice is the intentionality that Mike and the R&D team and product management put behind it. It's like when people walk into our showroom and they see Devil's Lock all in a four-pack and they look at the follow-up theme, which we showed at G2E last year and the better version of it you'll see this year is Honey Bomb in the exact same configuration. And then you talk about the rest of the roadmap after that, somebody goes, "Wow, I can put this on my floor, feel good about Devil's Lock, that family, that theme. And even if for some reason over time it drops off, here's the follow-up theme, and boy, they're already working on themes three and four."
And for a salesperson, there's nothing better than Mr. and Mrs. Customer, I'm going to defend these four games on your floor with my life. I don't want any more than that. And we've been super thoughtful about a variety of different types of games that are going to go in this same four pack on your floor. It really makes a lot of sense. Again, another real intentional thing that we've done here at Bluberi.
Don Retzlaff:
It's also something we preach to our clients is that when you're dealing with these non-big three smaller banks, you're going to have these games on your floor for five to seven years, these cabinets, I tell a lot of the people that once you buy a cabinet, you're probably going to have this machine on your floor until you retire because no one's replacing 20% of their cabinets anymore on a yearly basis like I used to. So once you buy this game, figuring and have it for 10 years and to have a roadmap that's going to be successful is just that one, it's great for the client, for the customer, and two, it's great for you, because it's the best way to expand. So looking at that, do you have a market share goal in mind or is it we want two to five banks at every property, like you mentioned with your recurring revenue?
Casey Whalen:
Yeah, I know we talk about this all the time. I mentioned it earlier, but living and dying by fair share. And I've been places before, again, where I oversold and to walk you through this scenario, if somebody said, "Hey, I want 20 games," and we feel like we can only support eight of them, if I did sell those 20 games, more than likely I would have no sale opportunities the next 24 months because all I would be doing is giving away either free hardware or free software on the performance guarantees because I cannot earn my share of the floor.
And so you ask about being strategic about it, it's like let's get a small bank out there, see how it performs, and if we have a winner, the customer's going to come back before we know it and ask for more games. So again, it depends on the location, the region, how mature the market is for us specifically. But with some of the data, and Andy running our data department and working between product management and sales, we talk a lot about it and it's got to be really strategic by property and then grow it in a healthy manner. It's not sophisticated, it's really just practical. But you got to be willing to do the work, gather the data, and then be willing to put your ego aside and say, "I'm not going to sell eight here. I'm only going to sell four because it's the right number."
Mike Brennan:
That being said, we definitely commanded some big orders, some openings, we've gotten six, 7% in some markets. If you look at the ship share numbers, you'd be surprised maybe at how many cabinets Bluberi has moved. So definitely have still moved volume, and we don't necessarily have a market share target. We have maybe a vendor order and ship share kind of targets and recurring revenue, money coming in targets.
But yeah, our goal is ambitious. We'd love to grow and grow until we're sitting right outside of the big three. But that is going to take a lot of strategy. It's going to take planning, and we're ahead of schedule for sure, from three years ago. But as Casey said, believe it or not, we have conversations about 2026 all the time as we're going quarter to quarter and looking to finish up this year. But no necessary market share, because it's different. We have big market share in the [inaudible 00:54:18] in Florida, in Alabama, in Washington state, and there's some places even California we're ticking up there. We're definitely aware of it, but it's very imbalanced state by state based on just how long Bluberi's been in that jurisdiction.
Don Retzlaff:
We just added a client recently and I looked at their floor and I kept seeing Royal Reels, Royal Reels, Royal Reels and banks of them, I mean dozens and dozens of them and they're like one of the top 10 themes on their floor. And talk about fair share and what they're doing above house average, I was just looking the other day at the manufacturers. I think there's only three manufacturers that we track that have 100% or better fair share on their floor. Most of them have 20% of the floor and 15% of the business, or 12% of the floor and 10% of the revenue. So I love the fair share. We do a lot with fair share is both on denominations, game types, manufacturers, all that type of stuff. So it's a great way to look at your game.
Mike Brennan:
Absolutely. One more quick aside. So Royal Reels is an EGS game that Bluberi made the software for. So that's all EGS product to be clear. And then the thing about fair share too is it's a goal of an operator in theory to bring everybody to 1X. Like our index was 1.7 for a while, and of course we have a low sample size, but it's coming down and it's hard not to get a bit bummed out, but that's the goal. We have places that it's like, "Oh, we're going to buy 24 more and so R&D is just going to come down." But we're fighting and scrapping. Can we keep it over this certain index? Can we do some other things to make it happen? But yeah, for sure over time that thing is like that's the goal to bring it down and it goes against the passion and pride here to keep it high. That's that ebb and flow that's happening right now.
Don Retzlaff:
Well, you'd be happy to know, I'm looking at your data now through all of our 100 plus clients saying your games are performing at 1.5 times house average. So that's pretty impressive as an overall goal. If your games are doing one and a half times house average, including stuff that probably shouldn't-
Nick Hogan:
That's the mean for the entire portfolio, Don?
Don Retzlaff:
It is, 1.5. 1.5 even.
Nick Hogan:
Oh, wow. There you go, guys. There's something you can talk about.
Don Retzlaff:
And some of that stuff's been out in the floor now for two plus years. You look at Devil's Lock, that came out the summer of '22, if I remember right. So you're still doing 1.5 and that's pretty impressive. I don't see anybody else that's doing that.
Mike Brennan:
Appreciate that.
Don Retzlaff:
The next big thing coming up is G2E. Anything you wanted to share or preview? I know we talked about a little bit about the introduction of the Beacon and Elite cabinets and the Beacon series. Anything else out there?
Casey Whalen:
Well, the last thing you want is the sales guy ruining any surprises or rolling anything out. This is Mike's department here and his baby, they've been working on for about nine months now. Actually, I will say last year, Thursday afternoon, three o'clock we're standing outside the booth and Mike looked into the booth and he said, "What are we going to do next year?" So this is always been something that's top of mind for Mike and the team since we closed up shop last year.
Mike Brennan:
Yeah. We said Casey's circle of trust is the equator, so definitely loves to share information. But yeah, no, I think with G2E our marketing, I'm really proud of our marketing team and our tagline is, this show is for you. So last year we had a greater than I think trying to encapsulate what we're doing in a quick tagline and we had some theories of like, "Hey, this is greater than that." We kind of showcase kind of our philosophies of Bluberi last year. This year it's really about the customer. And so it's like this blank is for you and we can write in different things. And really this show is for our operators, our customers. The first thing we're doing, this is kind of crazy, is the methodology. So the first thing I'll talk about what we're excited for G2E is we're taking a risk on how we present product. Talk about the way that a user experiences a trade show is probably not the most efficient way in the world.
And I've been lucky enough to have market research close to me across my career and we would ask questions. We do research at G2E and we'd never really enact it. So we're going to try to enact it this year. So we're going to try to give our customers a pod like experience, let's just say for when they come in and they can relax and be immersed in one little area. So that's the first thing is how we're presenting product, how we're theming our whole story on this show is for you, this game is for you. So the first thing is just how we're doing G2E. And then for products, I think we're bullish on quantity, exhibiting quality. We're going to have the most games, we're going to have a baker's dozen of new themes there, so lucky number 13. But each are kind of core plus or premium elite games.
The very diverse smattering of mechanics. We're really big on game mechanics too. So we talk about how this is leveraging this mechanic. This is sprinkling in this old school mechanic. And so overall we're pretty pumped about the whole experience and people coming in and having fun. We have a surprise at the back of the booth too. So if someone does take a position on our new cabinet, you're going to hear it, let's just say, and you're going to see it. So we got some fun cool stuff. Last year we did a lock wall. If you committed to Devil's Lock all in, you kind of put an old school Master Lock on a chain link fence and we've got something maybe with a bigger budget, but be careful, give us a slightly bigger budget and we've got something cool back there.
And then from theme standpoint, Casey, I love to hear what he's excited about, but it's a diversity of our content really. We have a pretty cool story. We really think about how we're going to present our products. A 45-minute tour, here's what we hope our customers remember because retention, it's a battle for retention at G2E. It's not a battle for showing 35 bonuses, I think. It's about how can our customers remember five things about Bluberi. And so I think we've got a good method here, but really we're excited about a couple games.
And I'll just say one, Timber Jack. So Timber, here comes the next potential strong game for us. We'll see. But it's got a lot of elements that echo what maybe worked in the past for us. What's worked in the industry. It's a lumberjack game. It's a sequel to a game that did well in Class II, we're about to release it in two-plus weeks. That's maybe our tent pole game that we want to talk about right now. It's a pretty neat game with these little pot filler beavers and some first-to-market elements, all with this lumberjack character. And we're going to embrace that game at G2E.
Nick Hogan:
Yeah, cool.
Casey Whalen:
Yeah, I would say, if I could just piggyback on that, I'm just excited for the other studios, specifically Reno and Moncton, to get to see their games in the wild, if you will. We've had such amazing results. Everybody likes to talk about Devil's Lock, but if you look at Xing Fu and Rocket Rumble, Double Flamin' Link, like our Canadian studios have done such an amazing job and been such a great foundation for us to build off of. But now the collaboration to get to have the other studios in there, and then even Mike Wabschall, who runs our Austin studio, he'll be in the booth to have our customers, the casino folks walk in and say, "Oh, here's the Moncton studio people and here's their games. Here's a couple games from them"
Like the diversity of the things that we're actually delivering on, that's what I'm most excited about is to say, "Yeah, we're not just single-sourced here anymore from really talented people, but we promised you we are going to diversify our portfolio. You should feel good about these investments you're making in this hardware, because the software software's coming from seasoned veterans that have been at other places and done really well." So I think for me, just the overall messaging and actually walking the walk is what I'm most excited about.
Nick Hogan:
Right. Cool. Excellent. Well, guys, I think we're a little, well, we're about at the hour mark here. Don, did you have more questions for Mike and Casey?
Don Retzlaff:
No, just continued good luck. A lot of people out there rooting for you. It's always nice to have options when you're buying slot machines and to go to a vendor like Bluberi and put on a couple banks and know they're going to be successful and it's just a welcome relief.
Nick Hogan:
Yeah. I'll second that. We've really had a good time. Well, from the data side, watching you guys, it's been cool. You've got some great stuff coming out. And indeed, I just echo what Don said, we wish you the greatest luck and yeah, we'll be crashing your party there at G2E for sure at some point.
Mike Brennan:
Yeah. Listen, one last thing is one of our core values is celebrate wins, but never be satisfied. And we're absolutely not satisfied. We know that things can move fast. So appreciate a chance to tell our story here, guys, and looking forward to spending more time with you.
Nick Hogan:
Yeah, excellent. Thank you so much for joining us today, guys. It was great having you.
Casey Whalen:
Thank you.
Nick Hogan:
Thanks.
Mike Brennan:
Sure.
        `,

};

export default S03E09;
